import React from "react";
import "./OurService.css";
import servone from "../../static/images/homessvg.svg";
import agile from "../../static/images/agile.gif";
import Reveal from 'react-reveal/Reveal';

const OurServices = () => {
  return (
    <>
      <Reveal effect="fadeInUp">
        <div className="container service-cont">
          <div className="service_desc">
            <p className="whatwedo">What We Do</p>
            <p className="whatwedodesc">
              We are a leader in developing world class software solutions that
              are tailor-made to meet the needs of our clients. We have a team
              of experienced software developers, designers, and project
              managers who are ready to help you with your software development
              needs.
            </p>
          </div>
          <div className="service_list">
            <div class="row servicerow">
              <div class="col s12 m4 l6">
                <div className="image_illustration">
                  <img src={servone} alt="imagetest" className="imagetest" />
                </div>
              </div>
              <div class="col s12 m4 l6">
                <div className="text_illustration">
                  <p className="text_illustration_title">How We work</p>
                  <p className="text_illustration_desc">
                    We involve you throughout our development process just to
                    make sure things are moving in the right direction with the
                    correct speed.
                  </p>
                </div>
              </div>
            </div>
            <div class="row servicerow">
              <div class="col s12 m4 l6">
                <div className="text_illustration">
                  <p className="text_illustration_title">
                    Software Development <br /> Services
                  </p>
                  <p className="text_illustration_desc">
                    We take your ideas and bring them to life by developing a
                    world class solution . We dont just end there , we also help
                    you through the process until you make you turn your idea
                    into reality.
                  </p>
                </div>
              </div>
              <div class="col s12 m4 l6">
                <div className="image_illustration">
                  <img src={agile} alt="imagetest" className="imagetest" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Reveal>
    </>
  );
};

export default OurServices;
