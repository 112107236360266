import React from "react";
import { Link } from "react-router-dom";
import OurServices from "./OurServices";
import home from "../../static/images/home_img.png";
import HomeProducts from '../products/HomeProducts'
import HomeServices from '../Services/HomeServices'
import Team from "../OurTeam/Team"
import "./Home.css";


const Home = () => {
  return (
    <>
      <div className="container">
        <div className="home">
          <div className="row">
            <div class="col s12 m4 l6 test">
              <p className="company_name">ONLY THE BEST</p>
              <p className="company_about">
                We are a leader in developing world class software solutions
                that help businesses to streamline operations and focus on their
                business models in order to add value to their business process.
              </p>
              <br />
              <p className="contact_home">
                <Link to="/contact">
                <button className="contact_home_btn">Contact Us</button>
                </Link>
              </p>
            </div>
            <div className="col s12 m8 l6">
              <img className="home_image" src={home} alt="otblabs" />
            </div>
          </div>
        </div>

        <OurServices style={{overflowX: 'hidden'}} />
        <HomeServices />
        <HomeProducts />
        {/* <Team /> */}
      </div>
    </>
  );
};

export default Home;
