import React from "react";
import "./About.css";
import team from "../AboutUS/team";
import { FaTwitter, FaLinkedinIn, FaGithubAlt } from "react-icons/fa";

const About = () => {
  return (
    <>
      <div className="container">
        <div className="row">
          {/* <div className="col-md-12">
            <div className="about-us">
              <p className="about_title">About Us</p>
              <hr className="about_hr" />
              <p className="more_about">
                OTBLABS is a software development company based in Nairobi,
                Kenya. We are a leader in developing world class software
                solutions that are tailored to meet your business needs.
              </p>
            </div>
          </div> */}
          <div className="col-md-12">
            <div className="team">
              <p className="team_title">Meet Our Team</p>
              {/* <hr className="team_hr" /> */}
            </div>
          </div>
          <div className="space"></div>
        </div>

        {/* Team images and data */}
        <div className="container service">
          {/* <p>Our Team</p>
          <p className="our_team_desc">
            Behind the scenes, we are a team of software engineers, designers,
            and data scientists. We are a team of passionate individuals who are
            passionate about building software that is easy to use, reliable,
            and scalable.
          </p> */}
          <div class="row">
            {team.map((devteam, index) => {
              return (
                <div class="col l4 m8 s12" key={index}>
                  <div
                    class="card"
                    style={{
                      margin: "10px",
                      borderRadius: "40px 40px 0px 40px",
                      width: "300px",
                      height: "fit-content",
                    }}
                  >
                    <div class="card-image">
                      <img src={devteam.image_url} />
                    </div>
                    <div class="card-content">
                      <p>{devteam.name}</p>
                      <p>{devteam.role}</p>
                    </div>
                    <div class="card-action">
                      {/* social media icons from react icons font awesome */}
                      <div class="social-icons">
                        <a href={devteam.twitter}>
                          <FaTwitter
                            style={{
                              backgroundColor: "#1DA1F2",
                              color: "white",
                              borderRadius: "50%",
                              padding: "2px",
                            }}
                            size={25}
                            target="_blank"
                          />
                        </a>
                        <a href={devteam.linkedin}>
                          <FaLinkedinIn
                            style={{
                              backgroundColor: "#1DA1F2",
                              color: "white",
                              borderRadius: "50%",
                              padding: "2px",
                            }}
                            size={25}
                          />
                          
                        </a>
                        <a href={devteam.github}>
                          <FaGithubAlt
                            style={{
                              backgroundColor: "#1DA1F2",
                              color: "white",
                              borderRadius: "50%",
                              padding: "2px",
                            }}
                            size={25}
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
