import React from "react";
import { Link } from "react-router-dom";
import homeService from "./homeservice";
import "./HomeService.css";
import Slide from "react-reveal/Slide";

const HomeServices = () => {
  return (
    <>
      <div className="container_service">
        <div>
          <p className="home_title">Our Services</p>
        </div>

        <div className="container service_home">
          <div className="row">
            {homeService.map((item, index) => {
              return (
                <Slide right>
                  <div className="col s12 m6 l4" key={index}>
                    <div className="card cardstyle" style={{ height: "250px" }}>
                      <div className="card-body">
                        <p className="icon_style">
                          {item.icon}
                          {/* <FaCode size={60} className="service_icon" /> */}
                        </p>
                        <h5 className="card-title">{item.service_name}</h5>
                        <p className="card-text">{item.service_description}</p>
                        {/* <a href="#" className="btn btn-primary">
                      Learn More
                    </a> */}
                      </div>
                    </div>
                  </div>
                </Slide>
              );
            })}
          </div>
        </div>
        <div className="button_service">
          <button className="homeBtnServices">
          <Link to="/services">
            <div class="btn-more">
              <span>More Services</span>
            </div>
          </Link>
          </button>
         
        </div>
        {/* <p>More Services</p> */}
      </div>
    </>
  );
};

export default HomeServices;
