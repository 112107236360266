import React, { useState } from "react";
import { FaMapMarker, FaPhoneAlt, FaEnvelope } from "react-icons/fa";
import headerContact from "../../static/images/service_image_one.jpeg";
import "./Contactus.css";
import axios from "axios";
import M from "materialize-css/dist/js/materialize.min.js";

const Contactus = () => {
  const [state, setstate] = useState({
    email: "",
    message: "",
    subject: "",
  });
  let { email, message, subject } = state;
  const submit = (e) => {
    e.preventDefault();
    if (email !== "" && subject !== "" && message !== "")
      axios
        .post(
          "https://rocky-headland-27282.herokuapp.com/api/otblabs/contact",
          {
            name: email,
            subject: subject,
            email: email,
            message: message,
          }
        )
        .then((res) => {
          M.toast({ html: "Email sent successfully" });
          setstate({
            email: "",
            message: "",
            subject: "",
          });
        })
        .catch((err) =>
          M.toast({ html: "Sending mail failed please try again" })
        );
  };
  return (
    <>
      <div className="container dissapear">
        <div className="header_contact">
          <div className="headerImage">
            <img
              className="servImage"
              src={headerContact}
              alt="otblabs Kenya"
            />
            <div className="headerService">
              <p className="headerServiceText">Contact Us</p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col s12 m8 l6">
            <div className="card bluecard">
              <div className="card-body">
                <div>
                  <p className="inTouch">Get in Touch With US</p>
                </div>
                <div className="contact_location">
                  <FaMapMarker style={{ fontSize: "30px", color: "#fff" }} />
                  <p className="details">
                    <span> Nairobi </span>
                    Kenya
                  </p>
                </div>
                <div className="contact_phone">
                  <FaPhoneAlt style={{ fontSize: "30px", color: "#fff" }} />
                  <p className="details"> +254 718728894</p>
                </div>
                <div className="contact_mail">
                  <FaEnvelope style={{ fontSize: "30px", color: "#fff" }} />
                  <p className="details">
                    <a className="details" href="mailto:admin@otblabs.co.ke">
                      admin@otblabs.co.ke
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col s12 m8 l6 ">
            <div className="card formcard">
              <div className="formTitle">
                <p>Message Us</p>
                <p>Fill Free To Reach out to us,</p>
              </div>
              <form onSubmit={(e) => submit(e)}>
                <div className="input-field ">
                  <input
                    placeholder="Enter email address"
                    id="email"
                    required
                    type="email"
                    value={email}
                    onChange={(e) =>
                      setstate({ ...state, email: e.target.value })
                    }
                    className="formInput"
                  />
                </div>
                <input
                  placeholder="Enter Subject"
                  id="subject"
                  required
                  type="text"
                  value={subject}
                  onChange={(e) =>
                    setstate({ ...state, subject: e.target.value })
                  }
                  className="formInput"
                />
                <div className="input-field">
                  <textarea
                    id="textarea1"
                    required
                    value={message}
                    onChange={(e) =>
                      setstate({ ...state, message: e.target.value })
                    }
                    placeholder="Enter Message"
                    className="formInput"
                  ></textarea>
                </div>
                <button className="submitMessage" type="submit">
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="container mobileContainer">
        <div className="header_contact">
          <div className="headerImage">
            <img
              className="servImage"
              src={headerContact}
              alt="otblabs Kenya"
            />
            <div className="headerService">
              <p className="headerServiceText">Contact Us</p>
            </div>
          </div>
        </div>
        <div className="card finalcard">
          <form>
            <div className="input-field inputField">
              <input
                placeholder="Enter email address"
                id="email"
                required
                value={email}
                onChange={(e) => setstate({ ...state, email: e.target.value })}
                type="email"
                className="formInput2"
              />
            </div>
            <div className="input-field inputField">
              <input
                placeholder="Enter Subject"
                id="subject"
                required
                value={subject}
                onChange={(e) =>
                  setstate({ ...state, subject: e.target.value })
                }
                type="text"
                className="formInput2"
              />
            </div>
            <div className="input-field inputField">
              <textarea
                id="textarea1"
                required
                value={message}
                onChange={(e) =>
                  setstate({ ...state, message: e.target.value })
                }
                placeholder="Enter Message"
                className="formInput2"
              ></textarea>
            </div>
            <button className="submitMessage2" type="submit">
              Submit
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default Contactus;
