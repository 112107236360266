import React from "react";
import "./Services.css";
import first from "../../static/images/service_image_two.jpeg";
import services from "./service";
import Slide from "react-reveal/Slide";

const Services = () => {
  return (
    <>
      <div className="container">
        <div className="service_main">
            <img className="serv_image"src={first} alt="services" />
            <div class="centered"><p className="services_title">Our Services</p></div>
            <div className="services_bg"></div>
        </div>
        <div className="services_cards">
          <div className="container services_padding">
            <div className="row">
              {services.map((item, index) => {
                return (
                  <Slide bottom>
                    <div
                      className="col s12 m6 l4"
                      key={index}
                      style={{ height: "300px" }}
                    >
                      <div
                        className="card cardstyle"
                        style={{ height: "250px", margin: "10px" }}
                      >
                        <div className="card-body">
                          <p className="icon_style">
                            {item.icon}
                            {/* <FaCode size={60} className="service_icon" /> */}
                          </p>
                          <h5 className="card-title">{item.service_name}</h5>
                          <p className="card-text">
                            {item.service_description}
                          </p>
                          {/* <a href="#" className="btn btn-primary">
                      Learn More
                    </a> */}
                        </div>
                      </div>
                    </div>
                  </Slide>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Services;
