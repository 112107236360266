import React, { Fragment } from "react";
import "materialize-css/dist/css/materialize.min.css";
import "./App.css";
import "@szhsin/react-menu/dist/index.css";
import { HashRouter as Router, Route, Switch } from "react-router-dom";
import Navbar from "./component/Navbar/Navbar";
import M from "materialize-css/dist/js/materialize.min.js";
import Footer from "./component/Footer/Footer";
import Contact from "./component/Contact/Contact";
import Contactus from "./component/Contact/Contactus";
import Developers from "./component/Developers/Developers";
import Startup from "./component/Startup/Startup";
import Products from "./component/products/Products";
import Services from "./component/Services/Services";
import Inhouse from "./component/Inhouse/Inhouse";
import About from "./component/AboutUS/About";
import Home from "./component/Home/Home";
import ScrollToTop from "./ScrollToTop";

function App() {
  let options = { accordion: false };
  var instances;
  document.addEventListener("DOMContentLoaded", function () {
    var elems = document.querySelectorAll(".sidenav");
    instances = M.Sidenav.init(elems, {});
  });

  document.addEventListener("DOMContentLoaded", function () {
    var elems = document.querySelectorAll(".collapsible");
    instances = M.Collapsible.init(elems, {});
  });

  return (
    <div className="App">
      <Router basename={"app"}>
        <ScrollToTop />
        <Fragment>
          <Navbar />
  
          <Route exact path="/" component={Home} />
          <Switch>
            <Route exact path="/contact" component={Contactus} />
            <Route exact path="/developers" component={Developers} />
            <Route exact path="/startup" component={Startup} />
            <Route exact path="/customers" component={Products} />
            <Route exact path="/services" component={Services} />
            <Route exact path="/products" component={Inhouse} />
            <Route exact path="/about" component={About} />
          </Switch>
          <Footer />
        </Fragment>
      </Router>
    </div>
  );
}

export default App;
