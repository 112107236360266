const team = [
  {
    id: 1,
    name: "Austine Gwa",
    image_url: "../images/gwa.png",
    role: "Software Engineer",
    linkedin: "https://www.linkedin.com/in/austinegwa",
    twitter: "  https://twitter.com/AustineGwa",
    github: "https://github.com/AustineGwa",
  },
  {
    id: 2,
    name: "Steve Chando",
    image_url: "../images/steve.png",
    role: "Software Engineer",
    github: "https://github.com/yogo254",
    linkedin: "www.linkedin.com/in/schando",
    twitter: "https://twitter.com/yogo_dev",
  },
  {
    id: 3,
    name: "Agesa Collins",
    image_url: "../images/agesa.png",
    role: "Software Engineer",
    linkedin: "https://www.linkedin.com/in/agesa-collins-15b15917a/",
    twitter: "https://twitter.com/AgesaCollins3",
    github: "https://github.com/agesa3",
  },
  {
    id: 4,
    name: "Enos Okello",
    image_url: "../images/enos.png",
    role: "Software Engineer",
    linkedin: "https://www.linkedin.com/okelloEnos",
    twitter: "https://twitter.com/okelloEnos",
    github: "https://github.com/okelloEnos",
  },
  {
    id: 5,
    name: "Natasha Kimathi",
    image_url: "../images/tasha.png",
    role: "UI/UX Designer",
    linkedin: "https://www.linkedin.com/in/natashakimathi78",
    twitter: "https://twitter.com/Tasha_Kimathi",
    github: "https://github.com/NatashaKimathi",
  },
  {
    id: 6,
    name: "Dennis Munania",
    image_url: "../images/denno.png",
    role: "UI/UX Designer",
    linkedin: "https://www.linkedin.com/in/dennis-munania-6905911a5",
    twitter: "https://twitter.com/_munania",
    github: "https://github.com/munania",
  },
];

export default team;
