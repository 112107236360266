import React from "react";
import "./footer.css";
import { Link } from "react-router-dom";
import {
  FaLinkedinIn,
  FaTwitter,
  FaInstagram,
  FaFacebook,
  FaMapMarker,
  FaPhoneAlt,
  FaEnvelope
} from "react-icons/fa";

const Footer = () => {
  return (
    <div>
      <footer class="footer-distributed">
        <div class="footer-left">
          <h3>OTBLABS</h3>
          <p class="footer-links">
            <Link to="/">Home</Link> | 
            <Link to="/contact">Contact Us</Link> | 
            <Link to="/services">Our Services</Link> |
            <Link to="/developers">Developers</Link>  
          </p>

          <p class="footer-company-name">
            &copy; {new Date().getFullYear()}
            <strong> OTBLABS</strong> All rights reserved
          </p>
        </div>

        <div class="footer-center">
          <div className="contact_footer">
           <FaMapMarker style={{ fontSize: "30px", color: "#fff" }} />
            <p>
              <span> Nairobi </span>
              Kenya
            </p>
          </div>

          <div className="contact_footer">
            <FaPhoneAlt  style={{ fontSize: "30px", color: "#fff" }} />
            <p> +254 718728894</p>
          </div>
          <div className="contact_footer">
            <FaEnvelope  style={{ fontSize: "30px", color: "#fff" }} />
            <p>
              <a href="mailto:admin@otblabs.co.ke">admin@otblabs.co.ke</a>
            </p>
          </div>
        </div>
        <div class="footer-right">
          <p class="footer-company-about">
            <span>About the company</span>
            <strong>OTBLABS</strong> is a software development company based in Nairobi, Kenya.
            We are a leader in developing world class software solutions that are tailored to meet your business needs.
          </p>
          <div class="footer-icons">
            <a
              href="https://facebook.com/otblabs_ke"
              target="_blank"
              rel="noreferrer"
            >
              <FaFacebook />
            </a>
            <a
              href="https://instagram.com/otblabs_ke"
              target="_blank"
              rel="noreferrer"
            >
              <FaInstagram />
            </a>
            <a
              href="https://www.linkedin.com/company/otblabs"
              target="_blank"
              rel="noreferrer"
            >
              <FaLinkedinIn />
            </a>
            <a
              href="https://twitter.com/otblabs_ke"
              target="_blank"
              rel="noreferrer"
            >
              <FaTwitter />
            </a>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
