import React from 'react'
import './Inhouse.css'
import Empty from "../../static/images/empty.png"

const Inhouse = () => {
    return (
        <>
        <div className="container center image_empty">
            <div className="image_empty">
               <img  className="image_empty_img" src={Empty} alt="otblabs" style={{paddingTop:"10%",maxWidth:"100%"}} />
            </div>
        </div>
        </>
    )
}

export default Inhouse
