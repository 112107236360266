import { FaCode,FaDatabase,FaMobileAlt,FaOpencart,FaRocketchat,FaMoneyBillWave,} from "react-icons/fa";
const services = [
    {
      id: 1,
      service_name: "Web Design",
      icon: <FaCode size={50} style={{color:"#0C65EA"}}/>,
      service_description: "We offer website  design and development.From the design phase to the development of the website,you will get a step by step walkthrough with our team.",
    },
    {
        id: 2,
        service_name: "Mobile App Development",
        icon: <FaMobileAlt size={50} style={{color:"#0C65EA"}} />,
        service_description: "Have an idea for a mobile app?Want to reach your customers through Mobile Apps?We got you covered.Get to have your app built whether IOS/Android Apps.",
      },
      {
        id: 3,
        service_name: "E-Commerce Site Development",
        icon: <FaOpencart size={50}  style={{color:"#0C65EA"}}/>,
        service_description: "Get to expand your business accross the border through the online presence of your business.",
      },
      {
        id: 4,
        service_name: "Mobile Money Intergration",
        icon: <FaMoneyBillWave size={50}  style={{color:"#0C65EA"}}/>,
        service_description: "Easen payments for your business and services through leveraging the power of mobile money services.",
      },
      {
        id: 5,
        service_name: "SMS Intergration",
        icon: <FaRocketchat size={50}  style={{color:"#0C65EA"}}/>,
        service_description: "Want to reach a wider scope of your customers.We offer SMS intergartions to your platform hence enabling you to send bulk SMS tou your customers",
      },
      {
        id: 1,
        service_name: "Poster/Flier Designs",
        icon: <FaCode size={50} style={{color:"#0C65EA"}} />,
        service_description: "Want to advertise and youre stuck on the best poster or flier for your services,busines or event?Then we got you covered,with our skilled designers get to have your Poster or flier designed by us.",
      },
      {
        id: 7,
        service_name: "Systems Development",
        icon: <FaCode size={50} style={{color:"#0C65EA"}} />,
        service_description: "Through our expertise,we build custom systems fpor your particular need, be it school systems,hotel management systems etc.",
      },
      {
        id: 8,
        service_name: "Database Design",
        icon: <FaDatabase size={50} style={{color:"#0C65EA"}} />,
        service_description: "Get to have a suitable database built and designed to meet your cistomer needs.",
      },
    
  ];
  
  export default services;
  