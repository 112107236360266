const products = [
  {
    id: 1,
    product_name: "Rentizy",
    image_url: "../images/rentizy.svg",
    description:
      "Rent payment solution that helps you budget, save and pay for rent.  ",
    product_url: "https://rentizy.co.ke/#/",
  },

  {
    id: 2,
    product_name: "Kox Staffing",
    image_url: "../images/koxx.png",
    description:
      "Delivering staffing and services for years through recruiting for business. ",
    product_url: "https://www.koxstaffing.com",
  },
  {
    id: 3,
    product_name: "Jabali Ent.",
    image_url: "../images/jabali.png",
    description: "Book tickets for big time shows from your favorite artists.",
    product_url: "https://www.jabalientertainment.com/",
  },
  {
    id: 4,
    product_name: "Liquor Store",
    image_url: "../images/liquor.png",
    description:
      "Get your drinks delivered to your doorstep by just a click of a button.",
    product_url: "/",
  },
  {
    id: 5,
    product_name: "Subirah",
    image_url: "../images/subirah.png",
    description:
      "We are your partner in your search for your lifetime partner.Online Dating site for you",
    product_url:
      "http://subirah.com/",
  },
  {
    id: 6,
    product_name: "Jaymoh Fabricators",
    image_url: "../images/jaymoh.png",
    description:
      "Dealers in Fabrication Services.",
    product_url:
      "/",
  },
  {
    id: 7,
    product_name: "Kenadd",
    image_url: "../images/kenadd.png",
    description:
      "Suppliers for home decor products for both exterior and interior spaces.",
    product_url:
      "http://kenadd.com/",
  },
  {
    id: 7,
    product_name: "Springpath",
    image_url: "../images/springpath.png",
    description:
      "Help meet the needs of vulnerable members of the society like OVCs, PLHIVs.",
    product_url:
      "https://springpathkenya.org/",
  }
];

export default products;
