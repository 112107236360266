import React from "react";
import "./developers.css";
import mpesa from "../../static/images/mpesa.png";
import { AiFillGithub } from "react-icons/ai";
import email from "../../static/images/pexels-miguel-á-padriñán-1591062.jpg";
import Fade from "react-reveal/Fade";
import { Helmet } from "react-helmet";

const Developers = () => {
  return (
    <div className="dev">
      <Helmet>
        <title>Developers | Otblabs </title>
        <meta
          name="description"
          content="We develop tools , libraries and project templates that helps
          developers to archive productivity at work while also maintaining
          the community collaboration."
        />
      </Helmet>
      <div className="title">
        <div>
          <Fade right>
            <p className="flow-text">
              We develop tools , libraries and project templates that helps
              developers to archive productivity at work while also maintaining
              the community collaboration.
            </p>
          </Fade>
        </div>
      </div>
      <div className="content">
        <Fade bottom>
          <h5>
            Below is a list of our products that developers can leverage for
            better productivity{" "}
          </h5>
        </Fade>
        <div className="projects">
          <Fade left>
            <div className="card">
              <div className="card-image">
                <img src={mpesa} alt="otblabs" />
                <span
                  className="card-title "
                  style={{ color: " #ef6718", fontSize: "small" }}
                >
                  Mpesa Api Client
                </span>
              </div>
              <div className="card-content">
                <p>
                  A java swing desktop application client implementation of our
                  internal daraja-API implementation.
                </p>
              </div>
              <div className="card-action center">
                <a
                  href="https://github.com/otblabs/mpesa-api-desktop-client"
                  target="_blank"
                  className="black-text"
                  rel="noreferrer"
                >
                  <AiFillGithub /> Source
                </a>
              </div>
            </div>
            <div className="card">
              <div className="card-image">
                <img src={email} alt="otblabs" />
                <span
                  className="card-title "
                  style={{ color: " #ef6718", fontSize: "small" }}
                >
                  Java Email Service
                </span>
              </div>
              <div className="card-content">
                <p>Send emails made easy using java mail api</p>
              </div>
              <div className="card-action center">
                <a
                  href="https://github.com/otblabs/mail-sender-service"
                  target="_blank"
                  className="black-text"
                  rel="noreferrer"
                >
                  <AiFillGithub /> Source
                </a>
              </div>
            </div>
          </Fade>
        </div>
      </div>
    </div>
  );
};

export default Developers;
