import React from "react";
import "./Products.css";
import { FaArrowRight } from "react-icons/fa";
// import product from "../../static/images/product_2.jpg";
import project from "./product";

const Products = () => {
  return (
    <>
      <div className="container some">
        <p>Some of our Customers</p>
      </div>
      <div className="container service">
        <div className="row">
          {project.map((item, index) => {
            return (
              <div className="col s12 m6 l4" key={index}>
                <div className="card">
                  <div className="inner_image">
                    <img
                      src={item.image_url}
                      className="image_product"
                      alt="otblabs kenya"
                    />
                  </div>

                  <div className="card-body">
                    <div className="arrow_left">
                      <h5 className="card-title">
                        <a
                          href={item.product_url}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {item.product_name}
                        </a>
                      </h5>
                      <p>
                        <a
                          href={item.product_url}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {" "}
                          <FaArrowRight className='arrow_icon' size={18} />
                        </a>
                      </p>
                    </div>

                    <p className="card-text">{item.description}</p>
                    {/* <a href="#" className="btn btn-primary">
                      Learn More
                    </a> */}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      {/* </div> */}
    </>
  );
};

export default Products;
