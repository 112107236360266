import React from "react";
import "./startup.css";
import Fade from "react-reveal/Fade";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

const Startup = () => {
  return (
    <div className="startup">
      <Helmet>
        <title>Startups & SMEs | Otblabs </title>
        <meta name="description" content="Our Services for Startups and SMEs" />
      </Helmet>
      <Fade bottom>
        <p className="flow-text">
          <span>Startups </span> - A startup might conjure up images of young,
          excitable business-minded bright sparks ready to take over the world
          with their idea, and this isn’t strictly inaccurate.
        </p>
        <p className="flow-text">
          Being unique, being different, standing out against other businesses
          is the key idea behind a startup company and they typically build on
          experience as they grow – adapting and changing with the way the
          market goes. One in ten startups might make it, and the rest could
          fail, so the unpredictability of a startup is another defining
          feature.
        </p>
        <Link
          to="/contact"
          className="btn  btn-large "
          style={{ backgroundColor: "#ef6718" }}
        >
          Contact Us
        </Link>
        <p className="flow-text">
          <span>SMEs</span> - SMEs are often paced at a slower growth rate and
          usually work with a known formula proven to be reliable. While the
          minds behind a startup are looking to explode onto the scene, SMEs are
          more likely to stay local, working towards coasting along at a
          sustained level for a long time.
        </p>

        <p className="flow-text">
          Be it an innovative consumer app or a next-gen enterprise system – we
          do it all, from product ideation and concept development to system
          rollout and scale-up. We envision, prototype, create and test products
          and solutions to find new competitive advantage for your business
        </p>

        <p className="flow-text">
          We're relentless about creating remarkable outcomes for our clients.
          Unfortunately, most teams are really good at building the "wrong thing
          really well". We want to help them build the right thing. Our goal is
          to help you validate your assumptions before you spend hundreds of
          thousands of shillings on the wrong thing. Don't guess, get Proof.
        </p>
        <Link
          to="/contact"
          className="btn  btn-large "
          style={{ backgroundColor: "#ef6718" }}
        >
          Contact Us
        </Link>
      </Fade>
    </div>
  );
};

export default Startup;
