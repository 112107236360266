const productsHome = [
  {
    id: 1,
    product_name: "Rentizy",
    image_url: "../images/rentizy.svg",
    description:
      "Rent payment solution that helps you budget, save and pay for rent.  ",
    product_url: "https://rentizy.co.ke/#/",
  },

  {
    id: 2,
    product_name: "Kox Staffing",
    image_url: "../images/koxx.png",
    description:
      "Delivering staffing and services for years through recruiting for business. ",
    product_url: "https://www.koxstaffing.com",
  },
  {
    id: 3,
    product_name: "Jabali Ent.",
    image_url: "../images/jabali.png",
    description: "Book tickets for big time shows from your favorite artists.",
    product_url: "https://www.jabalientertainment.com/",
  }
    
  ];
  
  export default productsHome;
  