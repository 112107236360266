import React from "react";
import { Link } from "react-router-dom";
import { FaBars } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import "./navbar.css";

const Navbar = () => {
  return (
    <div className="main-nav">
      <nav style={{ boxShadow: "0 4px 30px rgba(0, 0, 0, 0.10)" }}>
        <div className="logo">
          <Link style={{ color: "#0C65EA" }} to="/">
            Otblabs
          </Link>
        </div>

        <span
          data-target="mobile-demo"
          className="sidenav-trigger hide-on-large-only"
        >
          <FaBars
            style={{
              fontSize: "1.5em",
            }}
          />
        </span>
        <ul className="right hide-on-med-and-down links">
          <li>
            <NavLink
              to="/"
              activeClassName="current"
              exact={true}
              activeStyle={{ color: "#0C65EA" }}
            >
              Home
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/services"
              activeClassName="current"
              activeStyle={{ color: "#0C65EA" }}
            >
              Services
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/customers"
              activeClassName="current"
              activeStyle={{ color: "#0C65EA" }}
            >
              Our Customers
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/products"
              activeClassName="current"
              activeStyle={{ color: "#0C65EA" }}
            >
              Our Products
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/developers"
              activeClassName="current"
              activeStyle={{ color: "#0C65EA" }}
            >
              For Developers
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/about"
              activeClassName="current"
              activeStyle={{ color: "#0C65EA" }}
            >
              About US
            </NavLink>
          </li>
          <li className="contactus_nav">
            <button className="btn_contactus">
              <Link className="btnlink navcontact" to="/contact">
                Contact Us
              </Link>
            </button>
          </li>
        </ul>
      </nav>

      <ul class="sidenav" id="mobile-demo">
        <li>
          <Link className="white-text sidenav-close" to="/">
            Home
          </Link>
        </li>
      
        <li>
          <Link className="white-text sidenav-close" to="/services">
            Services
          </Link>
        </li>
        <li>
          <Link className="white-text sidenav-close" to="/customers">
            Our Customers
          </Link>
        </li>
        <li>
          <Link className="white-text sidenav-close" to="/products">
            {" "}
            Our Products
          </Link>
        </li>
        <li>
          <Link className="white-text sidenav-close" to="/developers">
            For Developers
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default Navbar;
