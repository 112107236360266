import React from "react";
import home from "./home";
import "./HomeProduct.css";
import Slide from "react-reveal/Slide";
import { Link } from "react-router-dom";

const HomeProducts = () => {
  return (
    <>
      <div className="products_home">
        <div className="products_title_home">Our Customers</div>
        <div className="products_home_container">
          We design and develop mobile apps that delight your users and grow
          your business. Enterprise-grade development combined with outstanding
          design
        </div>
        <div className="container homeservice">
          <div className="row">
            {home.map((item, index) => {
              return (
                <Slide left>
                  <div className="col s12 m6 l4" key={index}>
                    <div className="card">
                      <div className="inner_image">
                        <img
                          src={item.image_url}
                          className="image_product"
                          alt="product"
                        />
                      </div>
                      <div className="card-body">
                        <h5 className="card-title">
                          <a href={item.product_url} target="_blank" rel="noreferrer">
                            {item.product_name}
                          </a>
                        </h5>
                        <p className="card-text">{item.description}</p>
                        {/* <a href="#" className="btn btn-primary">
                      Learn More
                    </a> */}
                      </div>
                    </div>
                  </div>
                </Slide>
              );
            })}
          </div>
        </div>
        <div className="button_service">
          <button className="more_service_btn">
            <Link to="/products">
              <div className="btn_more_products">
                <span>View More</span>
              </div>
            </Link>
          </button>
        </div>
      </div>
    </>
  );
};

export default HomeProducts;
