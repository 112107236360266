import { FaCode,FaMobileAlt,FaOpencart} from "react-icons/fa";

const homeService = [
  {
    id: 1,
    service_name: "Web Design",
    icon: <FaCode size={50} style={{color:"#0C65EA"}}/>,
    service_description: "We offer website  design and development.From the design phase to the development of the website,you will get a step by step walkthrough with our team.",
  },
  {
      id: 2,
      service_name: "Mobile App Development",
      icon: <FaMobileAlt size={50} style={{color:"#0C65EA"}} />,
      service_description: "Have an idea for a mobile app?Want to reach your customers through Mobile Apps?We got you covered.Get to have your app built whether IOS/Android Apps.",
    },
    {
      id: 3,
      service_name: "E-Commerce Site Development",
      icon: <FaOpencart size={50} style={{color:"#0C65EA"}} />,
      service_description: "Get to expand your business accross the border through the online presence of your business.",
    }
  ];
  
  export default homeService;
  